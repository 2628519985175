export const palette = {
  alternate: {
    main: '#003764',
    light: '#FFFFFF',
    dark: '#E5EAF4',
  },
  cardShadow: 'rgba(23, 70, 161, .11)',
  type: 'light',
  primary: {
    main: '#54C0E8',
    contrastText: '#FFF',
    light: '#54C0E81e',
  },
  secondary: {
    main: '#003764',
    contrastText: '#FFF',
    light: '#0037641e',
  },
  text: {
    primary: '#183B56',
    secondary: '#5A6D8E',
    disabled: '#CCC',
    highlight: '#FFF',
    success: '#1CA179',
    border: '#E5EAF4',
  },
  divider: 'rgba(0, 0, 0, 0.12)',
  background: {
    main: '#54C0E81e',
    paper: '#FFF',
    default: '#FFF',
    footer: '#183550',
    level2: '#f7f8fb',
    level1: '#FFF',
    tableHead: '#DDDDDD',
    tableSubhead: '#54C0E81e',
    tableAlternate: '#F8F8F8',
    alternate: '#E3F1FD',
    success: '#DBEFDC',
    error: '#FFEAEA',
    secondary: '#F8D859',
  },
  verticalBar: {
    used: '#54C0E8',
    unused: '#54C0E866',
    unlimited: '#C0C0C0',
  }
};
