import { palette } from './palette';

export const homeStyle = {
  '.southern-family-container': {
    /* Hero Section - Styles */
    '& .home-hero-wrapper': {
      '& .section-header__title, & .section-header__subtitle': {
        color: palette.text.highlight,
      },
      '& .hero-subtitle': {
        maxWidth: 510,
      },
      '& .hero-section': {
        height: 'unset !important',

        '@media(min-width: 1200px)': {
          height: '740px !important',
        },
      },
      '& .hero-left-section': {
        '@media(min-width: 1200px)': {
          marginTop: -250,
        },
      },
    },

    /* Pricing & Benefits / Subscription - Styles */
    '& .subscription-wrapper': {
      color: palette.text.highlight,
      '& .subscription-bg': {
        height: '100%',
        background: `url('./images/price-pattern.webp') no-repeat`,
      },
      '& .section-header__title, & .section-header__subtitle': {
        color: palette.text.highlight,
      },
      '& #pricing-benefit-section': {
        '& .planCard': {
          '&:hover': {
            background: palette.primary.main,
            color: palette.text.primary,
            opacity: 0.9,
          },
          '& .highlight-divider': {
            backgroundColor: palette.text.disabled
          }
        },
        '& .savings-summary-link': {
          textDecoration: 'underline',
          cursor: 'pointer',
        },
      },
    },

    /* How it works - Styles */
    '& .howitworks-section': {
      '& .howitworks-title': {
        marginBottom: 0,
      },
      '& .how-cover-image': {
        height: 550,
      },
      '& .step-counter': {
        backgroundColor: palette.secondary.main,
        fontSize: 16,
      },
    },

    /* Testimonial - Styles */
    '& .testimonial-wrapper': {
      backgroundColor: palette.primary.light,

      '& .testimonialSection': {
        padding: '40px 32px',
  
        '@media(min-width: 900px)': {
          padding: '40px 16px 80px',
        },
        '& .testimonial-container': {
          '& .testimonial-name, & .testimonial-location, & .testimonial-message': {
            color: palette.text.primary,
          },
        },
      },
    },

    /* Static Page Hero Banners */
    '& .dashboard-hero-section, & .terms-hero-section, & .privacy-hero-section, & .hipaa-hero-section, & .book-appointment-hero-section, & .find-provider-section, & .plan-selection-section, & .checkout-hero-section': {
      '& .MuiTypography-root': {
        color: palette.text.highlight,
      }
    },

    /* Savings Summary */
    '& .savings-wrapper': {
      '& .savings-contact-card': {
        backgroundColor: palette.primary.light,
      },
      '& .savings-header': {
        color: `${palette.text.primary} !important`,
      },
    },

    /* Contact Card - Faq & Subscription Success */
    '& .faq-contact-wrapper': {
      backgroundColor: palette.primary.light,
    },

    '& .savings-hero-section': {
      height: 303,
      backgroundColor: 'unset',
      background: `url('./images/saving_summary.webp') no-repeat center`,
      backgroundSize: 'cover'
    },

    '& .faq-hero-section': {
      height: 303,
      backgroundColor: 'unset',
      background: `url('./images/faq.webp') no-repeat center`,
      backgroundSize: 'cover'
    },

    /* Create Subscriber - Checkout flow container */
    '& .checkout-flow-container': {
      '& .planCard': {
        '&:hover': {
          color: palette.text.primary,
        },
        '& .highlight-divider': {
          backgroundColor: palette.text.disabled
        }
      },
      '&.plan-selection-container': {
        '& .location-logo-image': {
          height: 'auto'
        },
        '& .hero-static-bg': {
          backgroundColor: palette.primary.light,
        }
      },
      '& .payment-title-root': {
        marginTop: 0
      },
    },

    /* Book Appointment */
    '& .morning-image, & .noon-image, & .evening-image': {
      fill: `${palette.primary.main} !important`,
    },
  },
};