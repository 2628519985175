import YesIcon from 'assets/images/yes-icon.svg';

import QuoteLeft from 'assets/images/quote-left.svg';

import SuccessImage from 'assets/images/create-success.svg';
import CancelRenewalImage from 'assets/images/cancel-renewal-v2.svg';
import CanceSubslImage from 'assets/images/sad-smiley.svg';
import ThumbsupImage from 'assets/images/thumbsup-smiley.svg';

import Logo from 'assets/images/logo.webp';
import FooterLogo from 'assets/images/footer-logo.webp';
import Theme from 'theme';

const {
  REACT_APP_GROUP_ID: groupId,
  REACT_APP_AWS_REGION: region,
  REACT_APP_COGNITO_POOL_ID: userPoolId,
  REACT_APP_COGNITO_WEB_CLIENT_ID: userPoolWebClientId,
  REACT_APP_CLIENT_HEADER: clientHeader = groupId,
} = process.env;

const config = {
  groupId,
  region,
  userPoolId,
  userPoolWebClientId,
  logo: Logo,
  cptCodesIcon: YesIcon,
  portalName: 'Dependable Dental',
  tokenName: 'southerFamilySubscriberToken',
  globalClass: 'southern-family-container',
  clientHeader,
  pricingType: 'standard-pricing',
  headerProps: {
    align: 'center'
  },
  homeTemplateProps: {
    heroSection: {
      variant: 1,
      subTitleClasses: 'text-highlight',
      primaryButtonProps: {
        to: '/find-a-provider'
      },
      heroImage: '/images/home-images/home-banner-img.webp',
      verticalAlignImage: 'bottom',
    },
    benefitsSection: {
      variant: 2,
      columns: 4,
      alignTitle: 'left',
      alignCard: 'left',
    },
    subscriptionSection: {
      tabVariant: 2,
      carouselResponsiveItems: {
        desktop: 3,
      }
    },
    howItWorksSection: {
      variant: 2,
      alignTitle: 'left',
      heroImage: '/images/home-images/how-it-works.webp',
    },
    testimonialSection: {
      sectionClass: 'section-container',
      variant: 3,
      quoteImage: QuoteLeft,
      messageType: 'h6',
      gridSpacing: {
        xSmall: 12,
        medium: 9,
      },
      carouselResponsiveItem: {
        superLarge: 1,
        large: 1,
      }
    },
  },
  subscriptionSuccess: {
    successImage: SuccessImage,
    enableBookAppointment: true,
  },
  findProviderSection: {},
  choosePlanSection: {
    locationLogo: Logo,
    cardVariant: 2,
    tabVariant: 2,
    planCardProps: {
      buttonProps: {
        normalColor: 'primary',
        highlightedColor: 'primary',
        normalVariant: "contained",
        highlightedVariant: "outlined",
      }
    },
    staticHeroSection: {
      enable: true,
      variant: 1,
    }
  },
  checkoutSection: {},
  savingsSummary: {},
  faqSection: {},
  dashboardProps: {
    showBenefitsUsage: true,
    cancelRenewal: {
      image: CancelRenewalImage,
    },
    cancelSubscription: {
      image: CanceSubslImage,
    },
    reactivateRenewal: {
      image: ThumbsupImage,
    },
  },
  footer: {
    logo: FooterLogo
  },
  loginPage: {
    bannerImage: './images/login.webp',
  },
  theme: Theme
};

export default config;